import React from 'react';
import { NavLink } from 'react-router-dom';
import './Header.css';

const Header = () => {
  return (
      <header className="header">
        <div className="logo-container">
          <img src={`${process.env.PUBLIC_URL}/assets/photos/tkp.png`} alt="Project Logo" className="logo" />
        </div>
        <nav className="menu-bar">
          <NavLink exact to="/" className="menu-link" activeClassName="active">Home</NavLink>
          <NavLink to="/mission" className="menu-link" activeClassName="active">Mission</NavLink>
          <NavLink to="/gallery" className="menu-link" activeClassName="active">Gallery</NavLink>
          <NavLink to="/resources" className="menu-link" activeClassName="active">Resources for Teachers</NavLink>
        </nav>
      </header>
  );
};

export default Header;
