import React from 'react';
import './Mission.css';
import { Link } from 'react-router-dom';

const Mission = () => {
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
      <div className="page-content mission-page">
        <div className="mission-content-container">
          <section className="mission-section">
            <h2>The Kindness Project</h2>
            <p>
              The Kindness Project is an initiative dedicated to fostering a culture of love, positivity, and support within college communities and beyond. This project serves as a vital platform for promoting acts of kindness and compassion, helping to build a sense of unity and togetherness among individuals from all walks of life.
            </p>
          </section>
          <section className="mission-section">
            <h2>Our Mission</h2>
            <p>
              The primary mission of The Kindness Project is to create a community where acts of kindness are a fundamental part of everyday life. We aim to inspire individuals to engage in meaningful actions that positively impact those around them, whether through service, words of affirmation, donations, or simple gestures. By doing so, we hope to remind everyone of the power and significance of kindness in making the world a better place.
            </p>
          </section>
          <section className="mission-section">
            <h2>Impact on College Students</h2>
            <p>
              The Kindness Project is particularly important for college students and everyday people as it offers a unique opportunity to connect with others in a meaningful way. For students, this initiative provides a sense of belonging and support during a critical time in their lives. It encourages them to look beyond their own challenges and contribute to the well-being of their peers and community members.
            </p>
          </section>
          <section className="mission-section">
            <h2>Activities and Initiatives</h2>
            <p>
              Throughout the semester, students have engaged in various creative and impactful activities to spread kindness on campus:
            </p>
            <ul>
              <li><strong>Opening Doors and Passing Out Free Waters:</strong> At the recreation center, students have been opening doors for others and handing out free bottles of water, promoting hydration and friendliness.</li>
              <li><strong>Taping Bags of Quarters on Vending Machines:</strong> Students have taped bags of quarters to vending machines, allowing others to enjoy a snack or drink on them.</li>
              <li><strong>Posting and Passing Out Affirmation Notes:</strong> To encourage positivity, students have posted and distributed notes with uplifting messages around campus.</li>
              <li><strong>Sticky Notes on Faculty Office Doors:</strong> Faculty and staff have received messages of appreciation and encouragement through sticky notes placed on their office doors.</li>
              <li><strong>"Take One, Leave One" Posters:</strong> In various campus buildings, posters with notes of encouragement invite students to take a positive message and leave one for someone else.</li>
              <li><strong>Free Hugs:</strong> Outside the TMB, students set up a stand offering free hugs and verbal encouragement to passersby.</li>
              <li><strong>Laundry Aid in Dorms:</strong> Individual bags of laundry detergent pods and dryer sheets have been placed in dorm laundry areas to assist students with their laundry needs.</li>
              <li><strong>Paying for Meals:</strong> In campus dining areas, students have paid for the meals of others, fostering a spirit of generosity.</li>
              <li><strong>Making Connections in the Quad:</strong> Students have offered to sit with others in the quad, making new connections and supporting those who might feel lonely.</li>
              <li><strong>Passing Out Candy:</strong> Bags of candy have been distributed to students on sidewalks and entering buildings, bringing smiles and sweetness to their day.</li>
              <li><strong>Providing Sanitary Products:</strong> Sanitary products have been placed in bathrooms of dorms and class buildings to support the hygiene needs of students.</li>
            </ul>
          </section>
          <section className="mission-section">
            <h2>The Power of Simple Gestures</h2>
            <p>
              The impact of The Kindness Project is immeasurable. Simple acts of kindness can have profound effects, offering comfort and support to those who need it most. By participating in this project, individuals experience the joy of giving and the warmth of community spirit. The ripple effect of these actions helps create a more compassionate and empathetic environment for everyone.
            </p>
          </section>
          <section className="mission-section">
            <h2>Get Involved</h2>
            <p>
              We invite everyone to join The Kindness Project and make a difference in their community. Whether you're looking to implement similar initiatives in your own classroom, workspace, or neighborhood, or simply want to learn more about how you can contribute, we are here to support you. Together, we can create a more loving and supportive world, one kind act at a time.
            </p>
            <p>
              For more information or to discuss ways to incorporate The Kindness Project into your area, please visit our available <Link to="/resources" onClick={handleScrollToTop}>resources</Link>. Your involvement can help spread kindness and make a lasting impact on those around you.
            </p>
          </section>
        </div>
      </div>
  );
};

export default Mission;
