import React from 'react';
import './Resources.css'; // Ensure you reference the correct CSS file

const Resources = () => {
  return (
      <div className="page-content resources-page">
        <div className="resources-container">
          <h1>Resources</h1>
          <p>Resources for teachers to implement the Kindness Project to your class will be available soon!</p>
        </div>
      </div>
  );
};

export default Resources;
