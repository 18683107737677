import React, {useState} from 'react';
import './Home.css';
import Slideshow from '../components/Slideshow';
import emailjs from 'emailjs-com';

const Home = () => {
  const [feedbackMessage, setFeedbackMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_xofal4i', 'template_aq29eem', e.target, 'ns2NFkeSFq8zM6Gos')
        .then((result) => {
          setFeedbackMessage({ text: 'Message sent successfully!', type: 'success' });
        }, (error) => {
          setFeedbackMessage({ text: 'Failed to send the message, please try again.', type: 'error' });
        });

    e.target.reset();
  };

  return (
      <div className="page-content home">
        <div className="content-container">
          <div className="hero-section">
            <div className="hero-image">
              <img src={`${process.env.PUBLIC_URL}/assets/photos/heroSectionImage.jpg`}
                   alt="Inspiring acts of kindness"/>
            </div>
          </div>
          <div className="author-section">
            <div className="author-text">
              <h2>Welcome!</h2>
              <p>I am so glad you are here to learn more about The Kindness Project and how you can incorporate it into
                your classrooms, workspace, and even your personal life. My name is Shea, and I graduated with a
                bachelor's degree in 2017 from Jacksonville State University. I furthered my education there by
                obtaining a master's degree in English Language and Literature in 2019.</p>
              <p>Since then, I’ve taught college English Comp, literature, and speech, which includes oral communication
                and professional development. I have also mentored enhanced models of English Composition.</p>
            </div>
            <div className="author-image">
              <img src={`${process.env.PUBLIC_URL}/assets/photos/shea.JPG`} alt="Shea at JSU"
                   className="shea-contact-image"/>
              <p className="founder-text"><strong>Shea Prickett - Founder</strong></p>
            </div>
          </div>
          <div className="impact-section">
            <div className="impact-text">
              <h2>Impact</h2>
              <p>In 2021, I
                had the pleasure of mentoring another professor’s students with an enhanced model of English Composition. Midway through
                the semester, she assigned her students a proposal essay with the objective to identify a need for
                kindness. I have always taken pride in incorporating what I consider to be “the real issues” in my own
                classrooms. I allow students to write about things they are passionate about—things they can apply to
                the real world and things that truly make a difference. This particular assignment inspired me to do
                more. With her permission, I adapted this topic into an experiential learning activity that my students
                could later write about.</p>
              <p>Over the next few semesters, I included this unit in my lesson plans and watched it evolve and grow
                into something new each time it was used. The ideas and impacts my students made on our college campus
                those first few semesters were immeasurable. They identified their own struggles as college students,
                took a critical approach to how they could change that for other students, and took action.</p>
              <p>As I ventured around campus, I saw bits and pieces of The Kindness Project everywhere. I noticed the
                signs my students had made and posted all over our campus buildings. I heard the sentiments other
                students shared as they walked down the hallways, and I saw the posts made by affected students on
                social media platforms. I knew this was just the beginning of what has now become The Kindness
                Project.</p>
              <p>Helping students make connections with a subject and providing them with experiential learning
                opportunities has, in my experience, made a world of a difference. Many of my students have found an
                appreciation for English and writing through this project. More importantly, I’ve been able to use my
                profession and my subject to encourage my students to be kind, caring, and productive members of
                society—and I couldn’t think of anything better than that.</p>
            </div>
            <div className="impact-images">

              <img src={`${process.env.PUBLIC_URL}/assets/photos/polaroid2.jpg`} alt="Another Polaroid"
                   className="project-image"/>
              <img src={`${process.env.PUBLIC_URL}/assets/photos/polaroid1.jpg`} alt="Kindness Project Polaroid"
                   className="project-image"/>
              <img src={`${process.env.PUBLIC_URL}/assets/photos/polaroid3.jpg`} alt="Another Polaroid"
                   className="project-image"/>
              <a href="/gallery" className="gallery-link">View Gallery</a>
            </div>
          </div>
          <div className="special-thanks-section">
            <div className="special-thanks-content">
              <div className="thanks-text">
                <h2>Special Thanks</h2>
                <p>Thank you, <a href="https://www.jsu.edu/english/faculty-staff/jennie-vaughn.html" target="_blank"
                                 rel="noopener noreferrer">Dr. Jennie Vaughn</a>, for inspiring this project and being
                  an
                  example of what kindness looks like. I am immensely grateful to have been assigned as a mentor for your students, to
                  have had the opportunity to learn from you, and to call you a friend.</p>
              </div>
              <div className="thanks-image">
                <img src={`${process.env.PUBLIC_URL}/assets/photos/Vaughn-Jennie.jpg`} alt="Dr. Jennie Vaughn"
                     className="jennie-image"/>
              </div>
            </div>
          </div>
          <div className="contact-section">
            <div className="contact-content">
              <h3>Contact The Kindness Project</h3>
              <form className="contact-form" onSubmit={sendEmail}>
                <label>
                  Name:
                  <input type="text" name="name" maxLength="50" required/>
                </label>
                <label>
                  Email:
                  <input type="email" name="email" maxLength="50" required/>
                </label>
                <label>
                  Message:
                  <textarea name="message" rows="8" maxLength="30000" required></textarea>
                </label>
                {feedbackMessage && (
                    <p className={`feedback-message ${feedbackMessage.type}`}>
                      {feedbackMessage.text}
                    </p>
                )}
                <button type="submit" className="contact-button">Send</button>
              </form>
            </div>
          </div>
        </div>
      </div>
  );
};

export default Home;
