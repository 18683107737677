import React, { useState } from 'react';
import './Gallery.css'; // Ensure you reference the correct CSS file

const images = [
  'photo2.jpg', 'photo3.jpg', 'photo4.jpg', 'photo5.jpg', 'photo6.jpg',
  'photo7.jpg', 'photo8.jpg', 'photo9.jpg', 'photo10.jpg', 'photo11.jpg',
  'photo12.jpg', 'photo13.jpg', 'photo14.jpg', 'photo15.jpg', 'photo16.jpg',
  'photo17.jpg', 'photo18.jpg', 'photo19.jpg', 'photo20.jpg', 'photo21.jpg'
];

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  return (
      <div className="page-content gallery-page">
        <div className="gallery-container">
          <h1>Gallery</h1>
          <div className="gallery-grid">
            {images.map((image, index) => (
                <img
                    key={index}
                    src={`/assets/photos/${image}`}
                    alt={`Gallery ${index + 1}`}
                    className="gallery-item"
                    onClick={() => openModal(image)}
                />
            ))}
          </div>
          {selectedImage && (
              <div className="modal" onClick={closeModal}>
                <span className="close">&times;</span>
                <img className="modal-content" src={`/assets/photos/${selectedImage}`} alt="Selected" />
              </div>
          )}
        </div>
      </div>
  );
};

export default Gallery;
